.lf-download-package {
  text-align: center;

  .message {
    font-size: 18px;
    font-weight: bold;
  }

  .size {
    color: #666666;
    margin-top: 3px;
  }

  .rs-btn {
    margin-top: 20px;
  }

  svg {
    width: 3em;
    height: 3em;
    margin-bottom: 15px;
  }

  .loader {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .instructions-heading {
    margin-top: 8px;
  }

  pre.instructions {
    margin: 0 auto;
    width: 250px;
    margin-bottom: 0px;
    text-align: left;
    margin-top: 8px;
    background-color: #eeeeee;
    padding: 8px;
    color: #333333;
    border-radius: 6px;
  }
}