.pages-builder {
  .debug-panel {
    .sandbox {
      padding: 15px;
    }

    .title {
      font-size: 1.1em;
      font-weight: bolder;
      padding: 5px;
      line-height: 1.3em;
      vertical-align: middle;
      background-color: #eeeeee;
      color: #333333;
    }

  .javascript-errors {
    background-color: #000000;
    padding: 10px;
    color: #999999;

    .message {
      color: orange;
    }
  }

  }
}