.tf-framework-value {
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;

  .icon {
    order: 0;
    flex: 0 0 auto;
    align-self: auto;
  }

  .name {
    margin-left: 10px;
    order: 0;
    flex: 1 0 auto;
    align-self: auto;
  }
}

.lf-toolbar-menu {
  .version {
    font-size: 12px;
    color: #999999;
  }
}

.lf-modal-js-editor {
  width: 1098px;
}

.pages-builder {
  --lf-header-size: 56px;
  display: flex;
  width: 100%;
  position: relative;

  .sidebar {
    flex: 0 0 480px;
    background-color: #ffffff;
    border-left: 2px solid #dddddd;
    height: calc(100vh - 56px);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    overflow: hidden;
    position: relative;

    .btn-close {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 10;
    }

    .sidebar-navigator {
      order: 0;
      flex: 0 0;
      align-self: auto;
      margin-top: 15px;
    }

    .sidebar-container {
      order: 0;
      flex: 1 0;
      align-self: auto;
      overflow-y: scroll;
    }
  }

  .form-previewer {
    height: calc(100vh - var(--lf-header-size));
    flex: 1 0 calc(100vw - 480px);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;

    .canvas {
      padding: 25px 10px;
      background-image: linear-gradient(#f4f4f6 1px, transparent 1px), linear-gradient(to right, #f4f4f6 1px, transparent 1px);
      background-size: 10px 10px;
      background-color: #fefefe;
      order: 0;
      flex: 1 0;
      align-self: auto;
      overflow-y: scroll;
    }
  }

  .add-field-placeholder {
    padding: 15px;
    text-align: center;
    border: 2px dotted #aaaaaa;
    text-transform: uppercase;
    font-size: 15px;
    color: #aaaaaa;
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
    text-decoration: none;

    &:hover {
      background-color: #eeeeee;
      border: 2px solid #eeeeee;
    }
  }



  .previewer {
    opacity: 1;
    border: 1px solid #dddddd;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 7px 7px 7px -8px rgba(0,0,0,0.1);
    padding: 20px;
  }

  .field-editor {
    padding: 15px;

    .id {
      margin-bottom: 10px;
    }
    .rs-form-group:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

.show-code {
  .syntax {
    max-height: calc(100vh - 100px);
  }
}
