.lf-phone-placeholder {

  padding: 3%;

  .header {
    text-align: center;
  }

  .info {
    padding-top: 5%;
    padding-bottom: 5%;
    font-size: 1.1em; 
  }
}