.lf-page-projects {
  position: relative;
  
  
  .error {
    text-align: center;
    padding-top: 80px;
  }

  .lf-label-forms {
    margin-top: 50px;
  }

  .forms {
    margin-top: 15px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .projects-sidebar {
    border-right: 1px solid #dddddd;
    background-color: #fafafa;
    height: calc(100vh - 56px);
  }

  .show-grid {
    .lf-label {
      margin-top: 0px;
    }
  }

  .project-detail {
    padding: 25px 200px 100px 200px;
    overflow-y: scroll;
    height: calc(100vh - 56px);

    .truncated {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .lf-project-title {
    color: #336699;
    font-size: 28px;
    font-weight: bold;

    svg {
      margin-left: 10px;
    }

  }
}