.lf-project-card {
  width: 250px;
  border: 1px solid #cccccc;
  padding: 5px 5px 2px 5px;
  min-height: 80px;
  display: inline-block;
  margin-bottom: 15px;
  border-radius: 4px;
  //position: relative;

  &:not(:last-child) {
    margin-right: 15px;
  }

  &.disabled {
    opacity: 0.5;
  }

  .form-menu {
    //position: absolute;
    //top: 5px;
    //right: 5px;
    float: right;
  }

  .name {
    color: #336699;
    font-size: 15px;
    text-decoration: none;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 24px);
    display: inline-block;
  }

  .description {
    font-size: 12px;
    color: #999999;
    display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
   min-height: 35px;

  }

  .footer {
    font-size: 12px;
    height: 17px;
    overflow: hidden;

    .visibility {
      float: left;
    }

    .count {
      float: right;
    }
  }

  &.cannot-use {
    opacity: 0.6;
  }

  &:hover:not(.cannot-use) {
    background-color: #fafafa;


  }

  .rs-dropdown {
    .rs-btn-icon.rs-btn-sm {
      padding: 2px;
      &:hover {
        box-shadow: none;
        background-color: #c1daef;
      }
    }
  }
}