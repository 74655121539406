.lf-page-github-landing-page {
  .lf-error-placeholder {
    margin-top: 120px;
  }

  .preview {
    width: 600px;
    height: 250px;
    background-image: url(../../images/github-landing-page.png);
    margin: 0 auto;
    display: block;
    box-shadow: 0 0 8px 8px white inset;
    background-size: contain;
    margin-top: 25px;
  }

  .lf-alchemic-loader {
    margin-top: 200px;
  }
}
