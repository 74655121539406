.pages-import-form {

  margin: 0 auto;
  margin-top: 80px;
  width: 60%;
  border: 1px solid #cccccc;
  padding: 50px 30px;
  border-radius: 4px;
  min-height: 300px;
  box-shadow: 7px 7px 7px -8px rgb(0 0 0 / 10%);

  h3 {
    color: #0077b6;
  }
  .action {
    margin-top: 15px;
    font-size: 16px;
    color: #666666;
  }

  .rs-btn-toolbar {
    margin-top: 35px;

  }
}