.lf-import-form {
  .errors {
    border: 1px solid #eebdd2;
    background-color: #ffddd2;
    padding: 15px;
    color: #000000;
    margin-bottom: 10px;
  }

  textarea {
    font-family: monospace;
    font-size: 13px;
  }

}