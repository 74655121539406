.lf-last-news-modal {

  .buttons {
    text-align: center;
    margin-top: 20px;

    .rs-btn {
      width: 150px;
    }
  }

  .lf-news {
    padding-left: 15px;
    padding-right: 15px;

    h1 {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 15px;
      color: #2368AF;
    }

    h2 {
      font-size: 18px;
      line-height: 18px;
      color: #2368AF;
    }

    h3, h4 {
      font-size: 17px;
      color: #2368afc4;
    }

    img {
      max-width: 100%;
    }

    .date {
      color: #888888;
    }

    ul {
      padding-left: 1rem;
      margin-top: 5px;
    }

    pre {
      background-color: #f6f6f6;
      padding: 6px;
      border: 1px solid #bbbbbb;
      border-radius: 4px;
    }
  }
}