.lf-select-connector-modal {

  .connectors {
    display: flex;
    flex-wrap: wrap;
    margin-left: 30px;
    margin-top: 10px;

    .connector {
      width: 300px;
      margin-right: 15px;
      margin-top: 15px;
      display: inline-block;
      padding: 4px;
      position: relative;

      .warning-cors {
        position: absolute;
        right: 3px;
        top: 3px;
      }

      .icon, svg {
        max-width: 16px;
        max-height: 16px;
        margin-right: 4px;
        margin-top: -3px;
      }

      &:hover {
        background-color: #eaeaea;
        border-radius: 4px;
        cursor: pointer;
      }

      .name {
        font-weight: bold;
        display: inline-block;
        color: #2368AF;
      }

      .description {
        //padding-left: 20px;
        font-size: 13px;
        color: #666666;
      }

    }

  }
}