.form-generator-field-wrapper {
  position: relative;
  --lf-highligh-color: #ff6633;

  .component * {
    cursor: move !important;
  }

  &.hovering {
    padding-bottom: 5px;
    border-bottom: 4px solid var(--lf-drop-highlight-color);
  }

  // highlight if hover with the mouse and if selected (but not if hovering with a dragged component)
  &.selected:not(.hovering) {
    outline: 3px solid var(--lf-highligh-color);
    border-radius: 3px;
  }

  &.hover {
    outline: 3px solid var(--lf-hover-color);
    border-radius: 3px;
  }

  &.handle {
    background-repeat: no-repeat;
    background-position: top;
    background-image: url(../assets/drag@2x.png);
    background-size: 25px;
    background-position-y: 4px;
    z-index: 1000;
  }

  &:not(:first-child) {
    margin-top: var(--lf-field-margin);
  }

  .component {
    order: 0;
    flex: 1 0;
    align-self: auto;
  }

  .selector {
    order: 0;
    flex: 0 0 10px;
    width: 10px;
    align-self: auto;
    margin-right: 10px;
    position: absolute;
    top: 0px;
    left: -30px;
    height: 100%;
    &:hover {
      cursor: move;
    }
  }

  .spacer {
    order: 0;
    flex: 0 0 4px;
    align-self: auto;
    margin-right: 10px;
  }

  > .buttons {
    --rs-btn-primary-bg: black;
    order: 0;
    flex: 0 0 10px;
    align-self: auto;
    margin-left: 5px;
    position: absolute;
    top: -3px;
    right: -3px;
    z-index: 9999;

    .rs-btn {
      border-radius: 0px;
      background-color: var(--lf-highligh-color);
      &:hover {
        background-color: black;
      }
    }

    .rs-btn:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    .rs-btn:last-child {
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
    }

    &.left {
      top: unset;
      bottom: 0px;
    }
  }

  &.selected {
    > .selector {
      background-color: orange;
    }
  }

  .buttons {
    font-size: 11px;
    text-align: right;
  }

  .hidden {
    position: absolute;
    bottom: -3px;
    right: -3px;
    background-color: var(--lf-highligh-color);
    color: #ffffff;
    font-size: 10px;
    padding: 1px 3px;
    border-radius: 3px;
    text-transform: uppercase;
  }
}

.lf-form-react-antd {
  .ant-input-suffix, .ant-select-clear {
    line-height: 1px;
  }
  .ant-select-selection-item-remove {
    svg {
      vertical-align: unset;
    }
  }
}

.form-generator-placeholder-wrapper + .form-generator-field-wrapper {
  // fields wrapper which are next to the placeholder wrapp which receives drops, don't need margin
  // it's like the are on top of the list
  margin-top: 0px;
}