
.lf-sign-in-up-banner-modal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  padding: 0px 25px 25px 25px;

  ul {
    margin-top: 20px;
    padding-left: 15px;
  }

  h3, h4 {
    color: #336699;
  }

  li::marker {
    color: #3498ff;
    font-size: 16px;
  }

  li {
    margin-top: 5px;
  }

  .left-column {
    flex: 2 0;

    .lf-login-form {
      width: 100%;
    } 
  }

  .right-column {
    flex: 2 0;
    border-left: 1px solid #dddddd;
    padding-left: 40px;
    margin-left: 40px;
  }

  .right-column-1-2 {
    flex: 1 0;
    border-left: 1px solid #dddddd;
    padding-left: 30px;
    margin-left: 30px;
  }
}
