.lf-feature-button {
  display: inline-block;

  &.cannot-use {
    opacity: 0.7;
  }
  
  .rs-badge-content {
    text-transform: uppercase;
    font-size: 10px;
    border-radius: 5px;
  }

}