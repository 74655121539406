.lf-page-terms {
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 18px;
  }
  padding: 60px 25%;
}