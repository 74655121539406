.lf-dnd-components {
  padding-bottom: 25px;

  .components {
    padding-left: 10px;
    margin-top: 10px;
  }

  .category-name {
    font-weight: bold;
    background-color: #e0e0e0;
    padding: 3px 5px 3px 5px;
    margin-top: 20px;
  }
}


.dnd-help {
  margin: 10px 10px 0px 10px;
}

.lf-component-card {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 0px;
  padding: 8px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
  text-decoration: none;
  border: 2px dashed #bbbbbb;
  border-radius: 4px;

  .icon {
    margin-right: 5px;
  }

  &:hover {
    cursor: move;
    background-color: #eeeeee;
  }

}

.lf-custom-component-card {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 0px;
  padding: 8px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
  text-decoration: none;
  border: 2px dashed #bbbbbb;
  border-radius: 4px;

  .icon {
    margin-right: 5px;
  }

  &:hover {
    cursor: move;
    background-color: #eeeeee;
  }

}