.lf-publish-form {

  .ui-clipboard-button {
    margin-left: 10px;
  }

  .preview-link {
    margin-top: 25px;
    margin-bottom: 55px;
    width: 100%;
    padding: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: rgb(224 242 254);
    border-radius: 6px;
    font-family: ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
  }

  .description {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .lf-form-plaintext {
    margin-bottom: 25px;
  }

  .rs-btn-toolbar {
    justify-content: center;
    margin-top: 20px;
  }

  .warning {
    padding-bottom: 25px;
  }
}