.ui-modal-wrapper {
  .rs-modal-footer {
    margin-top: 15px;
  }

  &.right {
    .rs-modal-footer {
      text-align: right;
    }
  }

  &.center {
    .rs-modal-footer {
      text-align: center;
    }
  }

  &.left {
    .rs-modal-footer {
      text-align: left;
    }
  }

  .btn-close.rs-btn-icon {
    position: absolute;

    top: 10px;
    right: 10px;
  }

  .rs-modal-header-close {
    display: none;
  }
}