.lf-page-settings {
  padding: 25px 200px;

  .rs-nav {
    margin-bottom: 30px;
  }

  .lf-lets-form {
    max-width: 600px;

    .lf-buttons {
      margin-top: 60px;
    }
  }

  .membership-plan {
    width: 80%;

    .label {
      font-weight: bold;

      &:not(:first-child) {
        margin-top: 15px;
      }
    }
  }

}