.rs-theme-light, :root {
  // override rsuite
  --rs-navbar-default-bg: brown !important;
  --rs-message-warning-bg: #ffdfc2 !important;
}

.navigation {
  border-bottom: 1px solid #dddddd;
  --rs-navbar-default-bg: #ffffff;

  .btn-help {
    margin-top: 10px;
    float: left;
    margin-right: 16px;
  }

  .btn-login-signup {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    float: left;
  }

  .right-menu {
    .rs-navbar-item:hover {
      background-color: unset;
    }
  }

  .rs-dropdown-menu {
    margin-right: 15px;
  }

  .item_version {
    font-size: 12px;
    color: #999999;
  }

  .lf-news-bar {
    align-items: center;
    border-radius: 0;
    color: inherit;
    display: flex;
    float: left;
    overflow: hidden;
    position: relative;
    background-color: #eeeeee;
    color: #000000;
    padding: 8px 16px;
    margin: 8px;
    border-radius: 4px;
    border-left: solid 3px #1675e0;
    cursor: pointer;


    .inner {
      max-width: 400px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .rs-btn {
      margin-left: 20px;
    }
  }
}