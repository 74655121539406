.lf-label {
  text-transform: uppercase;
  color: #999999;
  margin-top: 25px;

  svg {
    margin-left: 6px;
    margin-top: -4px;
  }

  .btn-create {
    margin-left: 15px;
    margin-top: -4px;
  }
}