.lf-toolbar {
  background-color: #ffffff;
  order: 0;
  flex: 0 0 auto;
  align-self: auto;
  height: 50px;

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #f2f2f5;

  .btn-forms-template {
    button {
      padding-right: 12px;
    }
    svg {
      display: none;
    }
  }

  .rs-badge-content {
    border-radius: 4px;
    background-color: #ff6633;
  }

  .rs-dropdown {
    .rs-btn {
      background-color: #ffffff !important;
    }
  }

  .rs-dropdown-item.rs-dropdown-item-with-icon svg {
    margin-right: 10px;
  }

  .form-name {
    display: inline-block;
    margin-top: 9px;
    font-size: 16px;

    .label-name {
      font-weight: 600;
    }
    .name {
      color: #666666;
      display: inline-block;
      margin-left: 5px;
    }

  }

}