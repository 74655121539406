.lf-preview-response {
  background-color: #011627;

  .status {
    padding: 4px 4px 0px 4px;
    font-size: 13px;
    color: #ffffff;

    .code {
      font-weight: bold;
      &.red {
        color: red;
      }
      &.green {
        color: green;
      }
    }
  }

  .url {
    font-size: 13px;
    color: #999999;
    padding-left: 4px;
    padding-right: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .error {
    padding-left: 4px;
    padding-right: 4px;
    color: #ff8000;
  }
}