.lf-textarea-clipboard {
  position: relative;
  background-color: #eeeeee;
  border-radius: 3px;

  .btn-copy {
    position: absolute;
    bottom: 8px;
    right: 6px;

    svg {
      color: #999999;
    }

    &:hover {
      svg {
        color: #1675e0;
      }
    }
  }

  .code-install {
    margin-top: 0px;
    margin-bottom: 10px;
    padding: 10px;
    width: calc(100% - 10px);
  }

  &.truncate {
    pre {
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 28px;
    }
  }
}