.lf-control-common-array {
  margin-top: 0px !important;

  &.lf-center {
    .lf-control-common-array-item {
      .buttons {
        align-self: center;
      }
    }   
  }
  &.lf-top {
    .lf-control-common-array-item {
      .buttons {
        align-self: flex-start;
      }
    }   
  }
  &.lf-bottom {
    .lf-control-common-array-item {
      .buttons {
        align-self: flex-end;
      }
    }   
  } 

  .lf-control-common-array-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    position: relative;
    border-left: 5px solid #dddddd;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 5px !important;

    /*.rs-form-group {
      margin-bottom: 5px !important;
    }*/

    > .inner-form {
      order: 0;
      flex: 1 0;
      align-self: auto;
      --lf-field-margin: 4px;
      --lf-field-column-margin: 10px;
    }

    > .buttons {
      flex: 0 0 auto;
      align-self: center;
      margin-left: 6px;
    }
  }

}

.lf-icon-button {
  display: inline-block;
  min-height: 16px;
  min-width: 16px;
  padding: 4px;
  border-radius: 3px;

  &.disabled {
    opacity: 0.6;
  }

  &:not(.disabled):hover {
    background-color: #eeeeee;
  }
}
