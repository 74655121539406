.lf-start-here-dnd {
  .drop-here {
    padding: 5px;
    border: 3px dashed #dddddd;
    border-radius: 6px;
    font-size: 13px;
    color: #999999;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  svg {
    margin: 0 auto;
    display: block;
  }
}

.lf-lets-form {
  // hide it if there's a component hovering
  &:has(.form-generator-placeholder-wrapper.hovering) {
    .start-here-dnd {
      margin-top: 57px;
      .drop-here {
        display: none !important;
      }
    }
  }
}