.lf-add-button {
  padding: 0px 5px;
  text-align: center;

  a {
    padding: 5px;
    text-align: center;
    border: 1px dotted #aaaaaa;
    text-transform: uppercase;
    font-size: 15px;
    color: #aaaaaa;
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
    text-decoration: none;
    border-radius: 4px;

    &:hover {
      background-color: #eeeeee;
      border: 1px solid #eeeeee;
    }
  }

  &.large {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 15px;
    border-radius: 5px;

    a {
      padding: 15px;
    }
  }

}