.lf-waiting-confirmation {

  .btn-check-activation {
    margin-top: 20px;
    clear: left;
  }

  .loader-activation {
    margin-top: 20px;
    clear: left;
  }

}