.lf-manage-translations {

  .lf-empty-placeholder {
    .legend {
      max-width: 60%;
      margin-top: 30px;
    }
  }

  .source-cell {
    .locale {
      font-size: 12px;
      color: #999999;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
    margin-bottom: 15px;

    .left {
      flex: 1 0;
      align-self: center;
    }

    .loader {
      flex: 0 0;
      align-self: center;
      margin-left: 10px;
      margin-right: 10px;
    }

    .right {
      flex: 0 0;
    }
  }
}

.lf-iso-lang {
  margin-left: 5px;
  padding: 0px 5px;
  border-radius: 3px;

  &:first-child {
    margin-left: 0px;
  }
}