.lf-error-boundary {
  
  h5 {
    margin-top: 15px;
  }

  h3 {
    color: #ff6633;
    text-align: center;
    margin-top: 15px;
  }

  .error-stack {
    font-family: monospace;
    font-size: 10px;
  }
  
  .error-description {
    font-family: monospace;
    font-size: 12px;
    color: #ff6633;
  }

  .buttons {
    text-align: center;
    margin-top: 15px;
  }
}