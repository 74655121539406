.lf-form-react-mantine {
  font-family: var(--mantine-font-family);
  font-size: var(--mantine-font-size-md);
  line-height: var(--mantine-line-height);
  background-color: var(--mantine-color-body);
  color: var(--mantine-color-text);
  -webkit-font-smoothing: var(--mantine-webkit-font-smoothing);
}

.lf-form-react-antd {
  font-family: var(--ant-font-family);
  font-size: var(--ant-font-size);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: var(--ant-color-text);
  font-size: var(--ant-font-size);
  line-height: var(--ant-line-height);
  list-style: none;
  font-family: var(--ant-font-family);
}

.lf-form-react-bootstrap {

  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: var(--ifm-background-color);
  color: var(--ifm-font-color-base);
  color-scheme: var(--ifm-color-scheme);
  font: var(--ifm-font-size-base) /var(--ifm-line-height-base) var(--ifm-font-family-base);
  text-rendering: optimizelegibility;
}
