.form-generator-placeholder-wrapper {
  position: relative;
  --lf-highligh-color: #ff6633;

  .component * {
    cursor: move !important;
  }


  // highlight if hover with the mouse and if selected (but not if hovering with a dragged component)
  &.selected:not(.hovering), &.hover {
    outline: 3px solid var(--lf-highligh-color);
    border-radius: 3px;
  }

  &:not(:first-child) {
    margin-top: var(--lf-field-margin);
  }

  .component {
    height: 10px;
  }

  &.hovering {
    border-bottom: 3px solid var(--lf-drop-highlight-color);
    margin-bottom: 3px;

    .component {
      height: 6px;
    }
  }

  /*.selector {
    order: 0;
    flex: 0 0 10px;
    width: 10px;
    align-self: auto;
    margin-right: 10px;
    position: absolute;
    top: 0px;
    left: -30px;
    height: 100%;
    &:hover {
      cursor: move;
    }
  }*/

  /*.spacer {
    order: 0;
    flex: 0 0 4px;
    align-self: auto;
    margin-right: 10px;
  }*/

}
