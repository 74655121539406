.lf-lets-form {

  .label-test-buttons {
    float: right;
    background-color: #cccccc;
    color: #555555;
    font-size: 10px;
    padding: 1px 3px;
    margin-top: -16px;
    border-top-left-radius: 3px;
    text-transform: uppercase;
  }

  &.lf-lets-form-edit-mode {
    .lf-buttons {
      padding: 10px;
      background-image: linear-gradient(45deg, #eeeeee 25%, #ffffff 25%, #ffffff 50%, #eeeeee 50%, #eeeeee 75%, #ffffff 75%, #ffffff 100%);
      background-size: 56.57px 56.57px;
    }
  }
}

.lf-form {
  --lf-field-margin: 16px;
  --lf-field-column-margin: 16px;
  --lf-font-size: 15px;
  --lf-field-button-margin: 10px;
  --lf-highligh-color: #ff6633;
  --lf-hover-color: #FF9F85;
  --lf-drop-highlight-color: #3498ff;
  --lf-field-margin-top: 5px;
  --lf-border-color: #e5e5ea;
  --lf-group-padding: 15px;
  --lf-group-header: 15px;
  --lf-buttons-margin: 32px;

  &.lf-form-buttons-align-center {
    .lf-buttons {
      justify-content: center;
    }
  }
  &.lf-form-buttons-align-left {
    .lf-buttons {
      justify-content: flex-start;
    }
  }
  &.lf-form-buttons-align-right {
    .lf-buttons {
      justify-content: flex-end;
    }
  }

  .lf-buttons {
    margin-top: var(--lf-buttons-margin);
  }

  // as a general rule, components have a top margin, unless they are the first of their container
  [class*=lf-control]:not(:first-child) {
    margin-top: var(--lf-field-margin);
    margin-bottom: 0px !important;
  }

  .lf-control-common-array {

    .lf-control-common-array-item {
      --lf-field-margin: 15px;

      [class^=lf-control] {
        margin-bottom: 0px;
      }

      [class^=lf-control]:not(:first-child) {
        //margin-top: var(--lf-field-margin);
        margin-top: 10px;
      }
    }
  }
}

.lf-icon-asterisk {
  margin-top: -3px;
  display: inline-block;
}

.lf-missing-component {
  border: 1px solid #bbbbbb;
  background-color: #f6f6f6;
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;

  .icon {
    order: 0;
    flex: 0 0;
    align-self: auto;
    margin-top: 2px;
  }

  .tag-component {
    background-color: #673ab7;
    color: #ffffff;
    font-size: 12px;
    padding: 1px 4px 2px 4px;
    border-radius: 3px;
    line-height: 17px;
  }

  .message {
    display: inline-block;
    margin-left: 10px;
    order: 0;
    flex: 1 0;
    align-self: auto;
  }
}