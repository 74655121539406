.lf-test-toolbar {
  position: absolute;
  top: 60px;
  left: 10px;
  box-shadow: 3px 3px 6px -2px rgba(0,0,0,0.1);
  z-index: 1000;

  button.rs-btn {
    background-color: #ffffff;

    &:focus {
      background-color: #ffffff;
    }
  }
}