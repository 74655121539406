.lf-export-panel {
  padding: 10px;

  p {
    margin-top: 8px;
  }

  .menus {
    text-align: right;
    margin-top: 10px;

    .rs-picker-subtle .rs-picker-toggle:focus, 
    .rs-picker-subtle .rs-picker-toggle:hover,
    .rs-picker-subtle .rs-picker-toggle:active {
      background-color: #ffffff;
    }


    .rs-picker-toggle.rs-btn-sm {
      font-size: 13px;
      line-height: 18px;
      //padding: 3px 10px;
    }
  }

  .file-size {
    font-size: 13px;
  }

  .size {
    .figure {
      font-weight: bold;
    }
    .unit {
      color: #999999;
    }
  }

  .buttons {
    td {
      padding: 10px 10px 0px 0px;
    }

    .legend {
      font-size: 12px;
    }
  }

  .button {
    margin-top: 10px;
    margin-right: 10px;
  }

  /*.buttons {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;

    .button {
      flex: 0 0 auto;
      margin-left: 5px;
      margin-right: 5px;
    }

    .legend {
      flex: 1 0;
      margin-left: 10px;
    }
  }*/
}