.lf-empty-placeholder {
  text-align: center;
  max-width: 400px;
  padding: 15px 10px;

  &.center {
    margin: 0 auto;
  }

  .legend {
    max-width: 300px;
    display: inline-block;
    text-align: center;
    font-size: 0.9em;
  }
}