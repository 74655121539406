.lf-js-editor {
  .help-section {
    padding-left: 10px;

    p {
      margin-bottom: 8px;
    }

    .parameter {
      margin-bottom: 8px;
    }

    .tip {
      margin-top: 10px;
      padding: 8px;
      border: 1px solid #89A7A7;
      background-color: #B5D8CC;
    }
  }
}