.lf-connectors-panel {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;

  .connectors {
    margin-top: 20px;
  }

  .buttons {
    margin-top: 15px;
  }
}