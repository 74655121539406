.lf-control-divider {
  min-height: 15px;
  font-size: 1px;
  display: flex;


  .bar {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}