.lf-group-wrapper {
  --lf-wrapper-highligh-color: #ff6633;
  --lf-wrapper-header-background: #dddddd;
  --lf-wrapper-dashed-line-color: #cccccc;
  --lf-highligh-color: #ff6633;

  margin-top: 10px;
  position: relative;
  outline: 3px dashed var(--lf-wrapper-dashed-line-color);
  border-radius: 3px;
  // this fixes this bug https://github.com/react-dnd/react-dnd/issues/832
  transform: translate3d(0, 0, 0);
  // with transform background becomes transparent, force it white
  background-color: #ffffff;

  &.hover {
    outline: 3px solid var(--lf-hover-color);
    border-radius: 3px;
    .group-toolbar {
      background-repeat: no-repeat;
      background-position: top;
      background-image: url(../../assets/drag-dark@2x.png);
      background-size: 25px;
      background-position-y: 4px;
      cursor: move;
    }
  }

  &.selected {
    outline: 3px solid var(--lf-wrapper-highligh-color);
    border-radius: 3px;
    .group-toolbar {
      background-repeat: no-repeat;
      background-position: top;
      background-image: url(../../assets/drag-dark@2x.png);
      background-size: 25px;
      background-position-y: 4px;
      cursor: move;
    }
  }

  .separator {
    color: #000000;
  }

  .group-toolbar {
    background-color: var(--lf-wrapper-header-background);
    font-size: 13px;
    padding: 2px 5px;

    a {
      color: #000000;
      text-decoration: none;

      &:hover {
        color: #3498ff;
        text-decoration: underline;
      }
    }

    .info {
      display: inline-block;

      .name {
        font-weight: 600;
      }

      .component-name {
        color: #333333;
      }
    }

    .buttons {
      float: right;

      a {
        padding: 0px 3px;
        display: inline-block;
      }

      a:hover {
        color: #ffffff;
        background-color: black;
        text-decoration: none;
        outline: 2px solid #000000;
      }
    }
  }

  .hidden {
    position: absolute;
    bottom: -3px;
    right: -3px;
    background-color: var(--lf-wrapper-highligh-color);
    color: #ffffff;
    font-size: 10px;
    padding: 1px 3px;
    border-radius: 3px;
    text-transform: uppercase;
  }

  .lf-control-group {
    // fix: inside group wrapper, the add button is attached to the bottom, doesn't work
    // the bottom margin unless there's a small padding
    padding-bottom: 1px;
  }

  .lf-control-tabs {
    // fix: inside group wrapper, the add button is attached to the bottom, doesn't work
    // the bottom margin unless there's a small padding
    padding-bottom: 1px;
  }

  &.array {
    --lf-wrapper-header-background: rgb(185, 214, 185);
    --lf-wrapper-dashed-line-color: rgb(185, 214, 185);
  }

  &.three-columns, &.two-columns, &.columns {
    --lf-wrapper-header-background: rgba(185, 185, 236, 0.929);
    --lf-wrapper-dashed-line-color: rgba(185, 185, 236, 0.929);
  }

  &.tabs {
    --lf-wrapper-header-background: #F2C1D1;
    --lf-wrapper-dashed-line-color: #F2C1D1;
  }
}

.lf-group-super-wrapper {
  .hover-placelhoder {
    display: block;
    margin-top: 10px;
    border-bottom: 4px solid var(--lf-drop-highlight-color);
  }

  .lf-column.lf-column-hidden {
    display: block !important;

    [class^=lf-control] {
      opacity: 0.3;
    }
  }
}
