.lf-error-box {
  background-color: #e3655b;
  color: #ffffff;
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 4px;
  font-weight: bold;
  border: 1px solid #BB544B;
}