.lf-alchemic-loader {
  --lf-loader-color: #bbbbbb;
  --lf-loader-size: 30px;
  --lf-loader-adjustment: 4px;
  margin: 0 auto;
  width: calc(var(--lf-loader-size) + 1px);
  height: var(--lf-loader-size);
  position: relative;
  border: 1px solid #dddddd;
  border-radius: 3px;
  overflow: hidden;

  .slide {
    width: var(--lf-loader-size);
    height: var(--lf-loader-size);
    position: absolute;
    left: calc(var(--lf-loader-size) * -1);
    line-height: var(--lf-loader-size);
    vertical-align: middle;
    font-size: calc(var(--lf-loader-size) - var(--lf-loader-adjustment));
    text-align: center;
    margin-top: calc(var(--lf-loader-adjustment) * -1);
    color: var(--lf-loader-color);
  }

  .in {
    left: -1px;
    transition: 0.7s;
  }
}