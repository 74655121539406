.ui-show-error {
  padding-bottom: 30px;

  .rs-icon {
    color: #f44336;
  }

  .text {
    padding-top: 10px;
  }
}