.lf-form-versions-modal {
  padding-bottom: 20px;

  .layout {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: stretch;

    > .left {
      order: 0;
      flex: 1 0;
      align-self: auto;
      padding: 0px 25px;
    }
  
    > .right {
      order: 0;
      flex: 0 0 250px;
      align-self: auto;
    }
  }

  .loader {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;  
  }

  .timeline-item {
    text-decoration: none;
    display: block;
    padding: 0px 3px;
    color: #333333;
    border: 2px solid #ffffff;

    .day {
      font-weight: bold;
      color: #333333;
    }

    .time {
      font-size: 13px;
      color: #999999;
    }

    &:hover {
      //background-color: #eeeeee;
      border: 2px dashed #dddddd;
      border-radius: 3px;
    }
  }
}