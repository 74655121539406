.lf-tag-popover {
  padding: 0px 5px;
  border-radius: 4px;
  margin-left: 0px !important;

  &:not(:last-child) {
    margin-right: 5px;
  }


  &.rs-tag-default {
    background-color: #dddddd;
  }
}

.lf-tag-popover-balloon {
  max-width: 450px;

  code {
    display: block;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .rs-popover-content {
     font-size: 14px;
    line-height: 18px;
  }
}