.lf-changelog-modal {

  .lf-changelog {
    padding-left: 15px;
    padding-right: 15px;

    h1 {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 15px;
      color: #2368AF;
    }

    h2 {
      font-size: 18px;
      line-height: 18px;
      color: #2368AF;
    }

    ul {
      padding-left: 1rem;
      margin-top: 5px;
    }

    pre {
      background-color: #f6f6f6;
      padding: 6px;
      border: 1px solid #bbbbbb;
      border-radius: 4px;
    }
  }
}