.lf-open-codesandbox {
  background-color: #000000;
  color: #ffffff;

  path {
    stroke: #dddddd;
  }

  &:hover,&:active,&:focus {
    background-color: #444444;
  }

  &.disabled {
    opacity: 0.5;
    background-color: #000000 !important;
    &:hover,:active,:disabled {
      background-color: #000000;
      color: #ffffff;
    }
  }
}

.lf-open-modal-codesandbox {
  background-color: #000000;
  color: #ffffff;

  path {
    stroke: #dddddd;
  }

  &:hover,&:active,&:focus {
    background-color: #444444;
  }
}

.lf-modal-codesandbox {
  text-align: center;

  .claim {
    padding: 20px 40px;
  }

  .hint {
    font-size: 13px;
    color: #666666;
  }
}