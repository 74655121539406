.lf-block-404 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  margin-top: 80px;

  .loader {
    flex: 0 1 auto;
    align-self: auto;
    font-size: 1.1em;
    margin-right: 50px;
  }

  .code {
    flex: 0 1 auto;
    align-self: auto;
    font-size: 2.3em;
    font-weight: bold;
    padding-right: 20px;
    border-right: 2px solid #999999;
  }

  .message {
    flex: 0 1 auto;
    align-self: auto;
    font-size: 1.1em;
    margin-left: 20px;
  }
}