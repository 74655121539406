.lf-control-three-columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  min-height: 20px;

  /*.left, .right, .center {
    .rs-form-control-wrapper {
      > .rs-input, > .rs-input-number {
        //width: auto;
      }
    }
  }*/

  .left {
    flex: 1 0;
    align-self: auto;
    margin-right: var(--lf-field-column-margin);
  }

  .center {
    margin-right: var(--lf-field-column-margin);
    flex: 1 0;
    align-self: auto;
  }

  .right {
    flex: 1 0;
    align-self: auto;
  }

  .left:empty {
    display: none;
  }

  &.layout-0-1-0 {
    .left {
      flex: 0 0;
    }
    .center {
      flex: 1 0;
    }
    .right {
      flex: 0 0;
    }
  }

  &.layout-1-0-0 {
    .left {
      flex: 1 0;
    }
    .center {
      flex: 0 0;
    }
    .right {
      flex: 0 0;
    }
  }

  &.layout-1-1-1 {
    .left {
      flex: 1 0;
    }
    .center {
      flex: 1 0;
    }
    .right {
      flex: 1 0;
    }
  }

  &.layout-1-1-2 {
    .left {
      flex: 1 0;
    }
    .center {
      flex: 1 0;
    }
    .right {
      flex: 2 0;
    }
  }

  &.layout-1-2-1 {
    .left {
      flex: 1 0;
    }
    .center {
      flex: 2 0;
    }
    .right {
      flex: 1 0;
    }
  }

  &.layout-2-1-1 {
    .left {
      flex: 2 0;
    }
    .center {
      flex: 1 0;
    }
    .right {
      flex: 1 0;
    }
  }

  &.layout-1-1-3 {
    .left {
      flex: 1 0;
    }
    .center {
      flex: 1 0;
    }
    .right {
      flex: 3 0;
    }
  }

  &.layout-1-3-1 {
    .left {
      flex: 1 0;
    }
    .center {
      flex: 3 0;
    }
    .right {
      flex: 1 0;
    }
  }

  &.layout-3-1-1 {
    .left {
      flex: 3 0;
    }
    .center {
      flex: 1 0;
    }
    .right {
      flex: 1 0;
    }
  }


}
