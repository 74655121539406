.lf-form {
  // overrid the field rule
  .lf-control-group:not(:first-child) {
    margin-top: calc(var(--lf-group-header) + var(--lf-field-margin));
  }
}

.lf-control-group {

  .header {
    svg {
      display: inline-block;
    }
  }

  &.lf-border-boxed {
    border-bottom: 1px solid var(--lf-border-color);
    border-left: 1px solid var(--lf-border-color);
    border-right: 1px solid var(--lf-border-color);

    .header {
      &:before {
        border-top: 1px solid var(--lf-border-color);
        content: "";
        flex: 1 0;
      }

      &:after {
        border-top: 1px solid var(--lf-border-color);
        content: "";
        flex: 1 0;
      }
    }

    .lf-group-content {
      padding-left: var(--lf-group-padding);
      padding-right: var(--lf-group-padding);
      padding-bottom: var(--lf-group-padding);
    }
  }

  &.lf-border-topBottom {
    border-bottom: 1px solid var(--lf-border-color);

    .header {
      &:before {
        border-top: 1px solid var(--lf-border-color);
        content: "";
        flex: 1 0;
      }

      &:after {
        border-top: 1px solid var(--lf-border-color);
        content: "";
        flex: 1 0;
      }
    }

    .lf-group-content {
      padding-bottom: var(--lf-group-padding);
    }
  }

  &.lf-border-top {
    .header {
      &:before {
        border-top: 1px solid var(--lf-border-color);
        content: "";
        flex: 1 0;
      }

      &:after {
        border-top: 1px solid var(--lf-border-color);
        content: "";
        flex: 1 0;
      }
    }
  }

  &.lf-border-bottom {
    border-bottom: 1px solid var(--lf-border-color);
  }

  .header {
    align-items: center;
    background: transparent;
    display: flex;
    height: 1px;
    flex-direction: row;
    margin: var(--lf-group-header) 0px;

    .inner-text {
      flex: 0 0 auto;
      padding: 0 12px;
      display: inline-block;
    }

    &.left {
      &:before {
        flex: 0 0 10px;
      }
    }

    &.right {
      &:after {
        flex: 0 0 10px;
      }
    }
  }
}
