.lf-omni-editor {
  .size {
    display: inline-block;
    margin-left: 10px;
    font-size: 13px;

    .unit {
      color: #666666;
    }
  }
}