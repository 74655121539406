.lf-install-form-modal {

  h1 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 15px;
    color: #2368AF;
  }

  .description {
    margin-bottom: 15px;
  }

  .form-import-form {
    margin-top: 15px;
  }
}