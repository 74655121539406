.lf-ask-feedback {
  position: fixed;
  bottom: 0px;
  left: 30px;
  z-index: 1000;

  .rs-btn {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.lf-leave-feedback-modal {
  .feedback {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}