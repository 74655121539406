.lf-playground-container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  border: 1px solid #bbbbbb;
  border-radius: 3px;

  .lf-logo {
    position: absolute;
    bottom: -5px;
    left: 5px;
  }

  > .left {
    display: flex;
    align-items: center;
    justify-content: center;
    //background-color: green;
    order: 0;
    flex: 1 0 auto;
    align-self: auto;

    .playground-name {
      color: #336699;
      font-size: 22px;
      font-weight: bold;
      position: absolute;
      left: 10px;
      top: 5px;
    }

    .component-previewer {
      width: 80%;
      text-align: center;
    }
  }

  > .right {

    order: 0;
    flex: 0 0 350px;
    align-self: auto;
    border-left: 1px solid #bbbbbb;
    padding: 20px;

    .playground-description {
      margin-bottom: 25px;
      font-size: 1.1em;
    }

  }
}
