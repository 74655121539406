.lf-export-package {
  .loader {
    margin-top: 8px;
  }

  .closer {

  }

  .select-projects {
    margin-top: 25px;
    margin-bottom: 80px;
  }

  .step {
    margin-top: 25px;
  }

  .step-3 {
    text-align: center;

    .message {
      font-size: 18px;
      font-weight: bold;
    }

    .size {
      color: #666666;
      margin-top: 3px;
    }

    button {
      margin-top: 20px;
    }

    svg {
      width: 3em;
      height: 3em;
      margin-bottom: 15px;
    }
  }

  .step-3-github {
    text-align: left;

    .lf-lets-form {
      width: 70%;
    }
  }


  .layout {

  }

  .buttons {
    margin-top: 25px;
    justify-content: center;
    display: flex;

    .left {
      flex: 1 0;
    }

    .right {
      flex: 1 0;
      margin-left: 20px;
    }

  }

  .meta {
    margin-top: 25px;
  }
}