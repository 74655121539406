.lf-page-playground {

  .lf-title {
    color: #336699;
    font-size: 28px;
    font-weight: bold;
  }

  .playground-configuration {
    margin-bottom: 50px;
  }

  .lf-page-playground-detail {
      padding: 25px 200px 100px 200px;
  }

  .lf-playground-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;

    > .left {
      order: 0;
      flex: 0 0 350px;
      align-self: auto;
    }

    > .right {
      order: 0;
      flex: 1 0 auto;
    }

    .playground-incipit {
      margin-bottom: 20px;
      margin-top: 10px;
    }

    .buttons {
      padding-bottom: 20px;
    }
  }
}