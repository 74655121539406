.lf-frameworks-icons {
  display: inline-block;

  .icon {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 4px;
    }
  }
}