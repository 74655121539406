.lf-template-search-toolbar {
  --rs-btn-subtle-hover-bg: #ffffff;
  margin-bottom: 20px;

  .rs-col:first-child {
    padding-left: 0px;
  }

  .rs-input-group-sm>.rs-input {
    padding-left: 4px;
  }

  .input-search {
    margin-left: 10px;
  }
}