.pages-builder {
  .form-editor {
    padding: 15px;

    .rules-buttons {
      margin-top: 15px;
    }

    .component-info {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: stretch;
      align-items: center;
      margin-bottom: 20px;

      .icon {
        order: 0;
        flex: 0 0;
        align-self: auto;
        height: 24px;
      }

      .info {
        order: 0;
        flex: 1 0 auto;
        align-self: auto;

        margin-left: 5px;

        .name {
          font-size: 1.2em;
          font-weight: bold;
          color: #2368AF;
        }
        .js {
          display: inline-block;
          margin-left: 10px;
          font-size: 0.9em;
          color: #666666;
        }
        .rs-tag-sm {
          line-height: 16px;
          padding: 0 5px;
          border-radius: 4px;
        }
      }

    }


    .id {
      margin-bottom: 10px;
    }
    .rs-form-group:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}
