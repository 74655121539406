.lf-signup-save-form {

  .buttons {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: center;

    .left {
      flex: 1 0;
    }

    .right {
      flex: 0 0 ;
    }
  }

  .dont-ask {
    margin-left: -15px;
  }
}