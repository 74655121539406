.lf-page-dashboard {
  padding: 50px 120px;

  .welcome {
    display: flex;
    color: #333333;
    font-size: 32px;
    font-weight: bold;

    .link_dashboard {
      text-decoration: underline;
    }

    .incipt {
      flex: 0 0 auto;
    }

    .name {
      flex: 1 0 250px;
      margin-left: 10px;
    }
  }

  .projects {
    margin-top: 15px;
  }

  .lf-label-projects {
    margin-top: 60px;
  }

  .shared-forms {
    max-width: 800px;
    margin-top: 10px;
  }

  .playground-forms {
    max-width: 800px;
    margin-top: 10px;
  }
}