.lf-error-placeholder {
  text-align: center;
  padding: 15px 10px;

  b {
    font-size: 1.2em;
  }

  svg {
    margin-bottom: 10px;
  }

  .legend {
    max-width: 300px;
    display: inline-block;
    text-align: center;
  }
}