.rs-tooltip {
  line-height: 1.4;
}

.rs-dropdown-menu {
  z-index: 100 !important;
}

.rs-modal-xmd {
  width: 960px !important;
}