.lf-builder-logo {

  position: absolute;
  bottom: 5px;
  left: 10px;
}

.lf-builder-logo-popover {
  .rs-popover-content {
    font-size: 14px;
  }
}
