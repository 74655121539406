.lf-breadcrumbs {
  color: #999999;
  padding-bottom: 35px;

  .icon {
    font-size: 21px;
    font-weight: bold;
  }

  a {
    color: #999999;
    text-decoration: none;

    &:hover {
      color: var(--rs-text-link);
      text-decoration: underline;
    }
  }
}