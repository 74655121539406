.start-here {
  text-align: center;

  svg {
    display: block;
    margin: 0 auto;
  }

  .hint {
    width: 300px;
    margin: 0 auto;
  }

}