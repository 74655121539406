.lf-settings-team-members {
  max-width: 650px;

  .lf-member {
    .name {
      font-weight: 600;
    }
    .email {
      font-size: 0.9em;
      color: #666666;
    }

    .avatar {
      display: flex;
      align-items: center;
    }

    .tags {
      display: flex;
      flex-direction: row;      
      justify-content: flex-end;
      align-content: center;
      align-items: center;
    }

    .names {
      flex-direction: column;
      align-items: flex-start;
      overflow: 'hidden';
    }

    .rs-tag {
      background-color: #888888;
      color: #ffffff;
      padding: 0px 5px;
      border-radius: 3px;
      text-transform: capitalize;
      font-size: 0.8em;
      flex: 0 0 auto;

      &.green {
        background-color: #009E60;
      }
    }
  }
}