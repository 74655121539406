.lf-code-viewer {
  .buttons {
    margin-bottom: 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: center;

    .left {
      flex: 1 1 ;
      align-self: auto;
      color: #666666;
    }

    .right {
      flex: 0 1;
    }
  }

  .syntax {
    border-radius: 4px;
  }
}