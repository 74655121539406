.lf-notification {

  .header {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .pages-notification {
    margin: 0 auto;
    width: 60%;
    border: 1px solid #cccccc;
    padding: 30px 30px 30px 30px;
    border-radius: 6px;
    min-height: 300px;
    box-shadow: 7px 7px 7px -8px rgb(0 0 0 / 10%);
    font-size: 17px;
    margin-top: 30px;

    h3 {
      color: #0077b6;
      margin-bottom: 25px;
    }

    .rs-btn {
      display: block;
      margin-top: 25px;
      width: 200px;
      margin: 45px auto;
    }
  }

  .footer {
    width: 60%;
    padding-top: 10px;
    font-size: 13px;
    color: #999999;
    text-align: center;
    margin: 0 auto;

    a {
      color: #999999;

      &:hover {
        color: #0077b6;
      }
    }
  }
}