.lf-select-templates {

  .template {
    margin-bottom: 30px;
    font-size: 15px;

    .meta {
      margin-top: 5px;

      &:is(:empty) {
        display: none;
      }
    }

    .buttons {
      margin-top: 16px;
    }

    .lf-frameworks-icons {
      display: block;
    }

    .rs-btn-toolbar {
      margin-bottom: 8px;
    }

    .name {
      font-size: 19px;
      font-weight: 700;
      color: #023e8a;

      .version {
        display: inline-block;
        margin-left: 10px;
        color: #999999;
        font-size: 12px;
      }
    }
  }
}