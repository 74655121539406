.App {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

// React-Boottrap imports
@import "~bootstrap/scss/bootstrap";

// Mantine imports
@import '@mantine/core/styles.css';

.ui-modal-wrapper {
  .rs-modal-body {
    padding-bottom: 0px;
  }
}

.lf-control-placeholder {
  p+p {
    margin-top: 0px;
  }
}

h4.rs-modal-title {
  color: #2368AF;
}