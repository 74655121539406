.lf-control-two-columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  min-height: 20px;

  .left {
    flex: 1 0;
    align-self: auto;
    margin-right: var(--lf-field-column-margin);
  }

  .right {
    flex: 1 0;
    align-self: auto;
  }

  &.layout-1-2 {
    .left {
      flex: 1 0;
    }
    .right {
      flex: 2 0;
    }
  }

  &.layout-1-3 {
    .left {
      flex: 1 0;
    }
    .right {
      flex: 3 0;
    }
  }

  &.layout-1-4 {
    .left {
      flex: 1 0;
    }
    .right {
      flex: 4 0;
    }
  }

  &.layout-1-5 {
    .left {
      flex: 1 0;
    }
    .right {
      flex: 4 0;
    }
  }

  &.layout-2-1 {
    .left {
      flex: 2 0;
    }
    .right {
      flex: 1 0;
    }
  }

  &.layout-3-1 {
    .left {
      flex: 3 0;
    }
    .right {
      flex: 1 0;
    }
  }

  &.layout-4-1 {
    .left {
      flex: 4 0;
    }
    .right {
      flex: 1 0;
    }
  }

  &.layout-5-1 {
    .left {
      flex: 4 0;
    }
    .right {
      flex: 1 0;
    }
  }

  &.layout-3-2 {
    .left {
      flex: 3 0;
    }
    .right {
      flex: 2 0;
    }
  }

  &.layout-2-3 {
    .left {
      flex: 2 0;
    }
    .right {
      flex: 3 0;
    }
  }

  &.layout-0-1 {
    .left {
      flex: 0 0 auto;
    }
    .right {
      flex: 1 0;
    }
  }

  &.layout-1-0 {
    .left {
      flex: 1 0;
    }
    .right {
      flex: 0 0 auto;
    }
  }
}

.lf-form-react-rsuite5 {
  .lf-two-columns {
    margin-bottom: var(--lf-field-margin);
  }
}