.lf-membership-plans {
  text-align: center;

  .loader {
    margin-top: 60px;
  }

  .plans {
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-end;
  }

  .plan {
    flex: 1 0;
    align-self: stretch;
    border: 1px solid #cccccc;
    border-radius: 6px;
    padding: 20px 10px;
    position: relative;

    &.disabled {
      opacity: 0.5;
    }

    &:not(:first-child) {
      margin-left: 15px;
    }

    .head {
      height: 95px;

      .name {
        font-size: 18px;
        font-weight: bolder;
        color: #1a1a1ab3;
        text-align: center;
      }

      .price {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-content: stretch;
        align-items: flex-start;

        .tag {
          flex: 0 1 auto;
          font-size: 30px;
          font-weight: bold;
          color: #1a1a1ab3;
        }

        .label {
          flex: 0 1 auto;
          color: #666666;
          font-size: 12px;
          margin-left: 6px;
          line-height: 10px;
          margin-top: 12px;
        }
      }
    }

    .description {
      margin-bottom: 50px;

      ul {
        padding-left: 1em;
      }

      li::marker {
        color: #3498ff;
        font-size: 16px;
      }
    }

    .triangle {
      position: absolute;
      top: 0px;
      right: 0px;;
      width: 40px;
      height: 40px;
      background: linear-gradient(to top right, #fff 0%, #fff 50%, #3498ff 50%, #3498ff 100%);
      text-align: right;
  }

    .button, .expire {
      text-align: center;
      position: absolute;
      bottom: 20px;
      width: 100%;
    }

    &:hover:not(&.selected) {
      background-color: #fafafa;
    }

    &.selected {
      border: 3px solid #3498ff;
    }
  }

  .info {
    color: #888888;
    text-align: center;
    margin-bottom: 35px;
    margin-top: 15px;

    .icons {
      color: #333333;
    }
  }
}