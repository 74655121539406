.lf-connector-item {
  position: relative;
  margin-top: 15px;

  > .icon, > svg {
    max-width: 16px;
    max-height: 16px;
    margin-right: 4px;
    margin-top: -3px;
  }

  .name {
    font-weight: bold;
    display: inline-block;
    color: #2368AF;
  }

  .description {

    font-size: 13px;
    color: #666666;
  }

  .toolbar {
    position: absolute;
    top: -6px;
    right: 0px;
  }


}