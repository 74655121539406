.lf-save-into-project {
  position: relative;

  h3, h4 {
    color: #336699;
  }

  .modal-layout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    padding: 0px 25px 25px 25px;

    .left-column {
      flex: 2 0;
    }

    .right-column {
      flex: 2 0;
      border-left: 1px solid #dddddd;
      padding-left: 20px;
      margin-left: 20px;
    }

    .right-column-1-2 {
      flex: 1 0;
      border-left: 1px solid #dddddd;
      padding-left: 20px;
      margin-left: 20px;
    }
  }

  .lf-lets-form {
    margin-top: 25px;
  }
}