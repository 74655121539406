.lf-dashboard-sidebar-list {

  .data {
    margin-top: 5px;
  }

  .rs-placeholder {
    margin-top: 10px;
  }

  .lf-list-row {
    border-bottom: 1px solid #cccccc;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: center;
    height: 30px;
    cursor: pointer;

    .left {
      flex: 1 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .right {
      flex: 0 0;
      white-space: nowrap;
      display: none;

      .rs-btn {
        margin-right: 5px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }

    &:hover {
      background-color: #f0f0f0;
      .right {
        display: unset;
      }
    }
  }
}