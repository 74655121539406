.lf-cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2147483645;
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
  background-color: #F0F8FF;
  border-top: 1px solid #87CEEB;;

  .buttons {
    position: absolute;
    top: 13px;
    right: 10px;

    .rs-btn {
      border-radius: 3px;
    }

    .rs-btn:first-child {
      margin-right: 10px;
    }
  }
}