.lf-login-page {
  display: flex;
  height: 100vh;
  width: 100%;

  > .left {
    flex: 2 0;
    position: relative;
    border-right: 1px solid #999999;

    .lf-sign-in-up {
      margin-left: 40px;
      margin-right: 40px;
      margin-top: 60px;
    }

    .version {
      position: absolute;
      right: 10px;
      bottom: 10px;
      color: #999999;
      font-size: 12px;
    }

    .logo {
      margin-top: 50px;
      margin-left: 40px;
    }

  }

  .bg-1 {
    background-image: url(./backgrounds/bg-1.jpg);
  }

  .bg-2 {
    background-image: url(./backgrounds/bg-2.jpg);
  }

  .bg-3 {
    background-image: url(./backgrounds/bg-3.jpg);
  }

  .bg-4 {
    background-image: url(./backgrounds/bg-4.jpg);
  }

  .bg-5 {
    background-image: url(./backgrounds/bg-5.jpg);
  }

  > .right {
    flex: 3 0;
    position: relative;


    background-size: cover;

    .quote {
      border-radius: 4px;
      background-color: #000000;
      opacity: 0.7;
      padding: 4px 8px;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      left: 20%;
      bottom: 30px;
      position: absolute;
      color: #ffffff;
      width: 60%;
    }

  }
}
