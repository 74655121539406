.lf-start-here {
  .start-here {
    width: 100%;
    text-align: center;
    margin: 0 auto;

    svg {
      margin: 0 auto;
      display: block;
    }
  }
}