.lf-sign-in-up {

  .rs-btn.black {
    background-color: #000000;
  }

  .not-a {
    display: inline-block;
    margin-left: 10px;
  }

  .label {
    font-size: 32px;
    font-weight: bolder;
    color: #336699;
    margin-bottom: 15px;
    display: inline-block;
  }

  .lf-login-form {
    width: 300px;
    .lf-buttons {
      .rs-btn-default {
        display: none;
      }
    }
  }

  .lf-signup-form {
    .lf-buttons {
      .rs-btn-default {
        display: none;
      }
    }
  }

  .providers {
    width: 300px;
  }

}