.lf-form-plaintext {
  font-size: var(--lf-font-size);

  .lf-plaintext-field-label {
    font-weight: 600;
    color: #333333;
  }

  .plaintext-value:empty::before {
    content: "-";
  }
}