.pages-builder {
  .lf-test-tag {

    .label-edit-mode {
      float: right;
      background-color: #FF8000;
      color: #ffffff;
      font-size: 10px;
      padding: 1px 5px 0px 5px;
      margin-top: -21px;
      margin-right: -21px;
      text-transform: uppercase;
      border-top-right-radius: 4px;
      border-bottom-left-radius: 1px;
    }

    .label-plaintext-mode {
      float: right;
      background-color: #72A0C1;
      color: #ffffff;
      font-size: 10px;
      padding: 1px 5px 0px 5px;
      margin-top: -21px;
      margin-right: -21px;
      text-transform: uppercase;
      border-top-right-radius: 4px;
      border-bottom-left-radius: 1px;
    }

    .label-preview-mode {
      float: right;
      background-color: #317873;
      color: #ffffff;
      font-size: 10px;
      padding: 1px 5px 0px 5px;
      margin-top: -21px;
      margin-right: -21px;
      text-transform: uppercase;
      border-top-right-radius: 4px;
      border-bottom-left-radius: 1px;
    }
  }
}
