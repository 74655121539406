.lf-control-popover {

  .rs-popover-content {
    margin-top: 0px;

    .tag {
      margin-top: 5px;
    }

    .icons {
      margin-top: 10px;
      svg {
        margin-right: 10px;
      }
    }
  }
}

.lf-control-picker {
  .rs-panel-body {
    margin-left: -3%;
    margin-top: -10px;
  }

  .info {
    padding-left: 20px;
  }

  .loader-container {
    height: 30px;
    margin-left: 25px;
    margin-top: 10px;
  }

  .control {

    width: 30%;
    padding: 5px;
    //min-height: 40px;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: center;
    text-decoration: none;
    border: 2px dashed #bbbbbb;
    border-radius: 4px;
    margin-left: 3%;
    margin-top: 10px;

    &.disabled {
      background-image: linear-gradient(45deg, #eeeeee 25%, transparent 25%, transparent 50%, #eeeeee 50%, #eeeeee 75%, transparent 75%, #fff);
      background-size: 25px 25px;
      cursor: default;
    }

    .icon {
      line-height: 1px;
      color: #999999;
      order: 0;
      flex: 0 0 20px;
    }

    .description {
      order: 1;
      flex: 1 0;
      margin-left: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      .name {
        color: #000000;
        font-size: 15px;
        font-weight: bold;
      }
    }

    .info {
      order: 2;
      flex: 0 0 20px;
      margin-left: 5px;
    }

    &:not(.disabled):hover {
      background-color: #eeeeee;
    }
  }

}