.lf-demo-tag {
  --lf-demo-tag-size: 60px;
  --lf-demo-tag-color: #49796B;

  position: relative;
  height: var(--lf-demo-tag-size);
  width: var(--lf-demo-tag-size);
  top: -1px;
  position: absolute;
  right: -1px;
  z-index: 100;
  border-top: calc(var(--lf-demo-tag-size) / 2) solid var(--lf-demo-tag-color);
  border-right: calc(var(--lf-demo-tag-size) / 2) solid var(--lf-demo-tag-color);
  border-left: calc(var(--lf-demo-tag-size) / 2) solid transparent;
  border-bottom: calc(var(--lf-demo-tag-size) / 2) solid transparent;
  border-radius: 4px;

  .inner {
    color: #ffffff;
    position: absolute;
    transform: rotate(45deg);
    top: -13px;
    right: -30px;
    font-size: 11px;
  }
}
